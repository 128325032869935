import { useQuery } from '@tanstack/react-query';
import CultureShowsDisplay from 'components/frontpage/culture/CultureShowsDisplay';
import { getCultureProgram } from 'lib/client/movieinfo';
import Settings from 'lib/types/Settings';
import { useState } from 'react';
//#region [Styles]

//#endregion

//#region [Props]
type ShowXCultureProps = {
	settings: Settings;
};
//#endregion

//#region [Component]
export default function ShowXCulture({ settings }: ShowXCultureProps) {
	const [numToLoad, setNumToLoad] = useState((settings.program_xshows_numtoshow ?? 3));
	const { isLoading, isError, data, error, isFetching } = useQuery({
		queryKey: ["culture-showx", numToLoad.toString()], // key
		queryFn: () => getCultureProgram(undefined, undefined, true, numToLoad), // loader function
		refetchOnWindowFocus: false,
		refetchInterval: 600000, // refretch automatically every 10 minutes
		staleTime: 599999,
	} // options
	);

	return <CultureShowsDisplay heading='Kulturprogrammet' settings={settings} data={data} error={error} isError={isError} isLoading={isLoading} isLoadingMore={isFetching} hideMoreLink={numToLoad === -1} showDate showAllShowsFunction={settings.program_shows_showall === "showall" ? () => setNumToLoad(-1) : undefined} />;
}
//#endregion