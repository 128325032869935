import SSectionHeader from 'components/framework/SSectionHeader';
import { SError, SNoShows, SProgramSection } from "components/frontpage/cinemaprogram/FrontPageShowsStyles";
import AllCultureShowsLink from 'components/frontpage/culture/AllCultureShowsLink';
import OneCultureShow from 'components/frontpage/culture/OneCultureShow';
import PleaseWait from 'components/loader/PleaseWait';
import { ITheatreProgram } from 'lib/types/Program';
import Settings from 'lib/types/Settings';
import styled from 'styled-components';

//#region [Styles]
const SHeader = styled(SSectionHeader)`
	margin-top: 25px;
	--color_local_border_override: var(--color_programelements_header_border);
`;

const SShowsContainer = styled.section`
	width: 100%;



	@media screen and (min-width: 1100px ) {
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		gap: 15px;
		flex-direction: row;
		justify-content: center;
	}
`;

const SOneCultureShow = styled(OneCultureShow)`
	margin-bottom: 15px;

	@media screen and (min-width: 1100px ) {
		flex: 0 0 calc(33.333% - (30px / 3) );
		width: calc(33.333% - (30px / 3) );
		margin-bottom: 0;
	}
`

//#endregion

//#region [Props]
type CultureShowsDisplayProps = {
	settings: Settings;
	heading?: string;
	isLoading?: boolean;
	isLoadingMore?: boolean;
	isError?: boolean;
	error?: object | unknown;
	data?: ITheatreProgram;
	hideMoreLink?: boolean;
	showDate?: boolean;
	showAllShowsFunction?: () => void
};
//#endregion

//#region [Component]
export default function CultureShowsDisplay({ settings, heading, isLoading = false, isLoadingMore = false, isError = false, error, data, hideMoreLink = false, showDate = false, showAllShowsFunction }: CultureShowsDisplayProps) {
	return <SProgramSection id="kulturprogram">
		{heading && <SHeader>{heading}</SHeader>}
		{isLoading && <PleaseWait />}
		{!isLoading && <>
			{isError && <SError>Det oppstod en aldri så liten feil...<pre>{JSON.stringify(error)}</pre></SError>}
			{!isError && <>
				<SShowsContainer>
					{data?.shows?.map(show => <SOneCultureShow key={`${show.movieVersionId}|${show.showStart}`} show={show} article={data.fwpakkeArticles?.[show.movieVersionId]} />)}
					{(data?.shows?.length ?? 0) === 0 && <SNoShows>For øyeblikket ingen forestillinger</SNoShows>}
				</SShowsContainer>

			</>}
			{!hideMoreLink && <AllCultureShowsLink settings={settings} showAllShowsFunction={showAllShowsFunction} />}
		</>}
		{!isLoading && isLoadingMore && <PleaseWait />}
	</SProgramSection>;
}
//#endregion